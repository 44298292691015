<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <search :search-action="searchData" />
        </b-col>
        <b-col md="auto">
          <b-button
            variant="primary"
            :to="$route.path + '/add'"
          >
            <FeatherIcon icon="PlusIcon" /> Oluştur
          </b-button>
        </b-col>
        <b-col
          v-if="detailSearch"
          cols="12"
        >
          <filter-data :filter-action="getFilteredData" />
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
        fixed
      >
        <template #cell(company)="data">
          <b-link
            class="text-body"
            :to="$route.path + '/view/' + data.item.id"
          >
            <div>{{ data.item.company }}</div>
            <div class="font-small-3 text-primary">
              {{ data.item.name }}
            </div>
          </b-link>
        </template>
        <template #cell(phone)="data">
          <div>
            {{ data.item.phone_region_code? '+' + data.item.phone_region_code : '' }} {{ data.item.phone }}
          </div>
        </template>
        <template #cell(country)="data">
          <div class="d-flex align-items-center">
            <div class="mr-1">
              <b-img :src="baseUrl + '/media/flags/' + data.item.flag_dir + '/flat/24.png'" />
            </div>
            <div>
              <div>
                {{ data.item.country }}
              </div>
              <div class="font-small-3 text-info">
                {{ data.item.city }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(control)="data">
          <div class="text-right">
            <list-buttons
              primary-text="Görüntüle"
              :primary-action="$route.path + '/view/' + data.item.id"
              :edit-action="$route.path + '/edit/' + data.item.id"
              :delete-action="removeData"
              :data-id="data.item.id"
            />
          </div>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BButton, BCardFooter, BLink, BImg,
} from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Search from '@/views/Admin/Customers/FilterData/Search.vue'
import FilterData from '@/views/Admin/Customers/FilterData/FilterData.vue'

const tableName = 'customers'
export default {
  name: 'CustomersIndex',
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BLink,
    BImg,
    ListButtons,
    Search,
    FilterData,
  },
  data() {
    return {
      baseUrl: this.$store.state.app.baseURL,
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'phone',
          label: 'TELEFON',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'sector',
          label: 'SEKTÖR',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'country',
          label: 'BÖLGE',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '180px' },
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.company AS company`,
          `${tableName}.phone AS phone`,
          `${tableName}.phone_region_code AS phone_region_code`,
          'sectors.title AS sector',
          'countries.title AS country',
          'countries.flag_dir AS flag_dir',
          'cities.title AS city',
        ],
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['customers/dataList']
    },
    dataCounts() {
      return this.$store.getters['customers/dataCounts']
    },
    saveData() {
      return this.$store.getters['customers/dataSaveStatus']
    },
    detailSearch() {
      return this.$store.getters['customers/detailSearch']
    },
    filterData() {
      return this.$store.getters['customers/filterData']
    },
    searchKeyword() {
      return this.$store.getters['customers/search']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      if (this.searchKeyword.keyword) {
        this.dataQuery.or_like = {
          'customers.company': this.searchKeyword.keyword,
          'customers.phone': this.searchKeyword.keyword,
          'customers.tax_number': this.searchKeyword.keyword,
        }
        this.getDataList()
      } else {
        this.dataQuery.or_like = {}
        this.getDataList()
      }
    },
    getFilteredData() {
      const where = {}
      const filterData = {
        id_sectors: [],
        id_activities: [],
      }
      if (this.filterData.id_meets) {
        where['customers.id_meets'] = this.filterData.id_meets
      }
      if (this.filterData.id_countries) {
        where['customers.id_countries'] = this.filterData.id_countries
      }
      if (this.filterData.id_cities) {
        where['customers.id_cities'] = this.filterData.id_cities
      }
      if (this.filterData.id_sectors) {
        filterData.id_sectors = this.filterData.id_sectors
      }
      if (this.filterData.id_activities) {
        filterData.id_activities = this.filterData.id_activities
      }
      this.dataQuery.where = where
      this.dataQuery.filterData = filterData
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('customers/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('customers/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
